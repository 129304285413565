import { ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from '@sentry/react';
import { Toaster } from '@shared/components/ui/toaster';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Routes from './Routes';
import ApiProvider from './api/api-provider';
import { AuthProvider } from './auth/auth-provider';
import { AnalyticsProvider } from './services/analytics/AnalyticsProvider';
import { IntercomProvider } from './services/intercom/IntercomProvider';
import theme from './styles/Styles';

function App() {
  return (
    <ErrorBoundary
      fallback={
        <div className="flex h-screen items-center justify-center">
          <h1>An error has occurred</h1>
        </div>
      }
    >
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <AnalyticsProvider>
            <IntercomProvider>
              <ApiProvider>
                <Router>
                  <Routes />
                </Router>
                <Toaster />
              </ApiProvider>
            </IntercomProvider>
          </AnalyticsProvider>
        </AuthProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
