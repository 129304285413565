import Page from '@app/components/layout/page/page';

export default function OtherGasEmissionsPage() {
  return (
    <Page
      title="Scope 1 - Other Gas Emissions"
      name="Scope 1 - Other Gas Emissions"
    >
      Other gas emissions
    </Page>
  );
}
