import Page from '@app/components/layout/page/page';

export default function StationaryCombustionPage() {
  return (
    <Page
      title="Scope 1 - Stationary Combustion"
      name="Scope 1 - Stationary Combustion"
    >
      Stationary combustion
    </Page>
  );
}
