import Page from '@app/components/layout/page/page';
import Alert from '@shared/components/alert';
import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import { Accordion } from '@shared/components/ui/accordion';
import { Button } from '@shared/components/ui/button';

import HubAccordionItem from './components/hub-accordion-item';
import HubCards from './components/hub-cards';

export default function ReportHubPage() {
  return (
    <Page name="Company Carbon Footprint Hub">
      <div className="flex">
        <Alert
          variant="info"
          title="Instant report preview"
          className="mb-4 ml-4 hidden h-min max-w-input md:order-2 lg:block"
          icon={<Icons.barChart className="size-4" />}
        >
          <Text>
            Get quick insights from your data across scopes and emissions
            categories with our instant preview.
          </Text>

          <Button className="mt-3 bg-teal-600 align-bottom">
            Generate Preview
          </Button>
        </Alert>
        <div className="w-full min-w-fit max-w-form grow">
          <Accordion
            type="multiple"
            defaultValue={['scope1', 'scope2', 'scope3']}
            className="w-full space-y-2"
          >
            <HubAccordionItem title="Scope 1 - Direct emissions" value="scope1">
              <Text className="mb-2">
                The emissions associated with fuels, such as diesel or wood,
                which you directly combust either on-site or in vehicles.
              </Text>
              <HubCards
                name="Stationary combustion"
                totalEmissions={43}
                description="Fuels combusted directly on your sites"
                status="4/4"
                type="sites"
                href="/company-reports/uuid/stationary-combustion"
                icon={<Icons.flame className=" text-orange-400" size={20} />}
              />
              <HubCards
                name="Mobile combustion"
                totalEmissions={35}
                description="Fuels combusted in your vehicles"
                status="2/4"
                type="sources"
                href="/company-reports/uuid/mobile-combustion"
                icon={<Icons.truck className=" text-orange-400" size={20} />}
              />
              <HubCards
                name="Other gas emissions"
                totalEmissions={12.42}
                description="From fridges, extinguishers and fermentation"
                status="4/4"
                type="sources"
                href="/company-reports/uuid/other-gas"
                icon={
                  <Icons.refrigerator className=" text-orange-400" size={20} />
                }
              />
            </HubAccordionItem>
            <HubAccordionItem
              title="Scope 2 - Indirect emissions"
              value="scope2"
            >
              <Text>
                The emissions associated with fuels, such as diesel or wood,
                which you directly combust either on-site or in vehicles.
              </Text>
              <HubCards
                name="Electricity"
                totalEmissions={67}
                description="Electricity consumed across your sites"
                status="4/4"
                type="sites"
                href="/company-reports/uuid/electricity"
                icon={<Icons.zap className=" text-orange-400" size={20} />}
              />
              <HubCards
                name="Other purchased energy"
                totalEmissions={0}
                description="Heating, cooling or steam supplied to your sites"
                status="4/4"
                type="sites"
                href="/company-reports/uuid/other-energy"
                icon={
                  <Icons.snowflake className=" text-orange-400" size={20} />
                }
                cardStatus="excluded"
              />
            </HubAccordionItem>

            <HubAccordionItem
              title="Scope 3 - Other indirect emissions"
              value="scope3"
            >
              <Text>
                Emissions that occur in your value chain that aren’t included in
                Scope 2. These can be both upstream and downstream.
              </Text>
              <HubCards
                name="Food and drink"
                totalEmissions={12.85}
                description="Food and drink you have purchased"
                status="435"
                type="items"
                href="/company-reports/uuid/food-and-drink"
                icon={<Icons.carrot className=" text-orange-400" size={20} />}
              />
              <HubCards
                name="Packaging"
                totalEmissions={0}
                description="Packaging materials you have purchased"
                status="435"
                type="items"
                href="/"
                cardStatus="disabled"
                icon={<Icons.package className=" text-orange-400" size={20} />}
              />
              <HubCards
                name="Waste"
                totalEmissions={0}
                description="Waste you have produced"
                status="435"
                type="items"
                href="/"
                cardStatus="disabled"
                icon={<Icons.recycle className=" text-orange-400" size={20} />}
              />
            </HubAccordionItem>
          </Accordion>
        </div>
      </div>
    </Page>
  );
}
