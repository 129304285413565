import Page from '@app/components/layout/page/page';

export default function OtherEnergyEmissionsPage() {
  return (
    <Page
      title="Scope 1 - Other Energy Emissions"
      name="Scope 1 - Other Energy Emissions"
    >
      Other Energy emissions
    </Page>
  );
}
