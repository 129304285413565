import Text from '@shared/components/content/text';
import { Button } from '@shared/components/ui/button';
import { cn } from '@shared/lib/utils';
import { ReactNode } from 'react';

interface OnboardingGuideTemplateProps {
  preTitle?: boolean;
  title: string;
  description: string | ReactNode;
  children: ReactNode;
  handlePrimaryAction: () => void;
  primaryActionText: string;
  handleSecondaryAction?: () => void;
  secondaryActionText?: string;
}

export default function OnboardingGuideTemplate({
  preTitle,
  title,
  description,
  children,
  handlePrimaryAction,
  primaryActionText,
  handleSecondaryAction,
  secondaryActionText,
}: OnboardingGuideTemplateProps) {
  return (
    <div className="flex size-full  items-center justify-center p-2 md:p-0">
      <div className="flex min-h-[586px] flex-col items-center text-center">
        <Text
          className={cn('mb-4 uppercase font-bold', {
            'opacity-0': !preTitle,
          })}
        >
          Getting started
        </Text>
        <Text variant="heroValue" className="mb-8 font-heading font-semibold">
          {title}
        </Text>
        {typeof description === 'string' ? (
          <Text className="mb-6 max-w-xl text-center">{description}</Text>
        ) : (
          <div className="mb-6 max-w-xl text-center">{description}</div>
        )}
        <div className="max-w-5xl">{children}</div>
        <div className="mx-auto mt-12 flex w-full max-w-lg justify-around">
          {secondaryActionText && (
            <Button
              variant="secondary"
              className="border border-primary-700 text-primary-700"
              onClick={handleSecondaryAction}
            >
              {secondaryActionText}
            </Button>
          )}

          <Button className="bg-primary-700" onClick={handlePrimaryAction}>
            {primaryActionText}
          </Button>
        </div>
      </div>
    </div>
  );
}
