import Page from '@app/components/layout/page/page';
import OnboardingGuideTemplate from '@app/onboarding/guide/components/onboarding-guide-template';
import { zodResolver } from '@hookform/resolvers/zod';
import DatePickerFormField from '@shared/components/form/date-picker-form-field';
import { Form } from '@shared/components/ui/form';
import { useToast } from '@shared/components/ui/use-toast';
import {
  addWeeks,
  addYears,
  isAfter,
  isBefore,
  isFuture,
  set,
  subDays,
  subWeeks,
  subYears,
} from 'date-fns';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { date, object, z } from 'zod';

export const reportSetupSchema = object({
  startDate: date({
    required_error: 'Start date is required',
  }),
  endDate: date({
    required_error: 'End date is required',
  }),
});

const getEndDateFromStartDate = (startDate: Date) => {
  return subDays(addYears(startDate, 1), 1);
};

export default function ReportSetup() {
  const navigate = useNavigate();
  const { toast } = useToast();

  const form = useForm<z.infer<typeof reportSetupSchema>>({
    resolver: zodResolver(reportSetupSchema),
  });

  const { getValues } = form;

  const handleStartDateChange = () => {
    const startDate = getValues('startDate');
    const endDate = getEndDateFromStartDate(startDate);
    form.setValue('endDate', endDate);
  };

  const onSubmit = async (data: z.infer<typeof reportSetupSchema>) => {
    toast({
      title: 'Successfully submitted dates',
      variant: 'success',
      description: (
        <pre className="mt-2 w-full rounded-md bg-gray-950 p-4">
          <code className="w-full text-gray-00">
            {JSON.stringify(data, null, 2)}
          </code>
        </pre>
      ),
    });
    navigate('/company-reports/uuid');
  };

  return (
    <Page name="Report setup">
      <OnboardingGuideTemplate
        title="Your reporting period"
        description=" We recommend aligning your carbon reporting with your financial year as some regulations, such as CSRD, tie carbon reports to your financial reports"
        handlePrimaryAction={() => {
          form.handleSubmit(onSubmit)();
        }}
        primaryActionText="Set period"
        secondaryActionText="Go back"
        handleSecondaryAction={() => {
          navigate('/company-reports/welcome');
        }}
      >
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DatePickerFormField
              name="startDate"
              label="Start date"
              includeErrorMessage
              className="mb-6"
              handleSelect={handleStartDateChange}
              defaultMonth={set(subYears(new Date(), 1), {
                date: 1,
                month: 0,
              })}
              disabledDates={isFuture}
            />
            <DatePickerFormField
              name="endDate"
              label="End date"
              placeholder="Select a start date"
              defaultMonth={getValues('endDate')}
              disabledDates={(date) => {
                const endDate = getEndDateFromStartDate(getValues('startDate'));
                return (
                  isBefore(date, subWeeks(endDate, 2)) ||
                  isAfter(date, addWeeks(endDate, 2))
                );
              }}
              disabled={
                !getValues('startDate') || getValues('startDate') === null
              }
              required
            />
          </form>
        </Form>
      </OnboardingGuideTemplate>
    </Page>
  );
}
