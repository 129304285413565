import Page from '@app/components/layout/page/page';
import OnboardingGuideTemplate from '@app/onboarding/guide/components/onboarding-guide-template';
import Text from '@shared/components/content/text';
import { useNavigate } from 'react-router-dom';

export default function ReportSetupWelcome() {
  const navigate = useNavigate();

  return (
    <Page name="Report welcome page">
      <OnboardingGuideTemplate
        title="Company Carbon Footprint"
        description={
          <div>
            <Text className="mb-4">
              Time to start building your first annual carbon report with My
              Emissions!
            </Text>
            <Text>
              We understand this can be a lengthy and technical process, so
              we’ve tried our best to make things as simple as possible, you can
              find guides throughout the process and can always message us if
              you are unsure how to proceed. Good luck!
            </Text>
          </div>
        }
        handlePrimaryAction={() => {
          navigate('/company-reports/setup');
        }}
        primaryActionText="Get started"
      >
        <div className="size-full bg-gray-100"></div>
      </OnboardingGuideTemplate>
    </Page>
  );
}
