import ProductCard from '@app/components/product-card';
import { useGetProductsList } from '@shared/api';
import {
  AppProductsListEmissionsCategoryItem,
  AppProductsListSortItem,
  CollectionDetail,
  EmissionsCategoryEnum,
  ProductList,
  StatusEnum,
} from '@shared/api/types';
import { BadgeCell } from '@shared/components/data-table/cells/badge-cell';
import { DateCell } from '@shared/components/data-table/cells/date-cell';
import { HeaderCell } from '@shared/components/data-table/cells/header-cell';
import { NumberCell } from '@shared/components/data-table/cells/number-cell';
import { DataTable } from '@shared/components/data-table/data-table';
import { DataTableToolbar } from '@shared/components/data-table/data-table-toolbar';
import { useGetStaticFilterOptions } from '@shared/components/data-table/hooks/use-get-static-filter-options';
import {
  schema,
  useServerDataTable,
} from '@shared/components/data-table/hooks/use-server-data-table';
import {
  DataTableFilterField,
  DataTableProps,
} from '@shared/components/data-table/types';
import Loader from '@shared/components/loader';
import RatingCloud, {
  RatingCloudProps,
} from '@shared/components/ratings/rating-cloud';
import { Checkbox } from '@shared/components/ui/checkbox';
import { capitalizeFirstLetter, cn } from '@shared/lib/utils';
import { ColumnDef } from '@tanstack/react-table';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { ProductTableRowActions } from './product-table-row-actions';
import { ProductsTableToolbarActions } from './product-table-toolbar-actions';
import { useStatusFilterOptions } from './use-status-filter-options';

interface ProductTableProps extends Partial<DataTableProps> {
  type?: 'table' | 'card';
  collectionUuid?: CollectionDetail['uuid'];
}

function getColumns(): ColumnDef<ProductList>[] {
  return [
    {
      accessorKey: 'uuid',
      show: false,
      enableHiding: true,
    },
    {
      id: 'select',
      enableHiding: true,
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && 'indeterminate')
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
          className="translate-y-0.5 border border-primary-200 bg-gray-00 text-gray-00"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
          className="translate-y-0.5"
        />
      ),
      enableSorting: false,
    },
    {
      accessorKey: 'name',
      meta: {
        label: 'Name',
        sorting: {
          ascLabel: 'Name (A-Z)',
          descLabel: 'Name (Z-A)',
        },
      },
      header: ({ column }) => (
        <HeaderCell column={column} name="Product" className="text-gray-00" />
      ),
      cell: ({ getValue }) => {
        const name = getValue<string>();
        return <p className="min-w-[150px] max-w-[240px] truncate">{name}</p>;
      },
      enableHiding: false,
    },
    {
      accessorKey: 'status',
      header: ({ column }) => (
        <HeaderCell column={column} name="Status" textWhite />
      ),
      cell: ({ getValue }) => {
        const status = getValue<StatusEnum>();
        return (
          <BadgeCell
            text={
              status === 'in progress'
                ? 'Submitted'
                : capitalizeFirstLetter(status)
            }
          />
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: 'assessmentDate',
      meta: {
        label: 'Assessment Date',
        sorting: {
          ascLabel: 'Assessment Date (Oldest first)',
          descLabel: 'Assessment Date (Newest first)',
        },
      },
      enableSorting: true,
      sortingFn: 'alphanumeric',
      sortUndefined: 1,
      header: ({ column }) => (
        <HeaderCell column={column} name="Assessment Date" textWhite />
      ),
      cell: ({ getValue }) => {
        const date = getValue<string>();
        return (date && <DateCell date={date} />) ?? null;
      },
    },
    {
      accessorKey: 'totalEmissions',
      meta: {
        label: 'Total Emissions',
        sorting: {
          ascLabel: 'Total Emissions (Lowest first)',
          descLabel: 'Total Emissions (Highest first)',
        },
      },
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Total Emissions"
          numberColumn
          unit={
            <span>
              (gCO<sub>2</sub>e)
            </span>
          }
          textWhite
        />
      ),
      cell: ({ getValue }) => {
        const totalEmissions = getValue<number>();

        return (
          (totalEmissions && (
            <NumberCell number={totalEmissions! * 1000} rounded />
          )) ??
          null
        );
      },
    },
    {
      accessorKey: 'totalEmissionsPerKg',
      meta: {
        label: 'Carbon Intensity',
        sorting: {
          ascLabel: 'Carbon Intensity (Lowest first)',
          descLabel: 'Carbon Intensity (Highest first)',
        },
      },
      header: ({ column }) => (
        <HeaderCell
          column={column}
          name="Carbon Intensity"
          numberColumn
          unit={
            <span>
              (kgCO<sub>2</sub>e/kg<sub>food</sub>)
            </span>
          }
          textWhite
        />
      ),
      cell: ({ getValue }) => {
        const totalEmissions: number = getValue<number>();
        return (
          (totalEmissions && <NumberCell number={totalEmissions} rounded />) ??
          null
        );
      },
    },
    {
      accessorKey: 'emissionsCategory',
      header: ({ column }) => (
        <HeaderCell column={column} name="Rating" textWhite />
      ),
      cell: ({ getValue, row }) => {
        const rating = getValue<RatingCloudProps['rating']>();
        const status = row.getValue<StatusEnum>('status');
        return (
          (rating && (
            <RatingCloud
              rating={rating as RatingCloudProps['rating']}
              status={status}
            />
          )) ??
          null
        );
      },
      enableSorting: false,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        return <ProductTableRowActions product={row.original} />;
      },
    },
  ] as ColumnDef<ProductList>[];
}

export interface SortOption {
  id: string;
  label: string;
}

const productTableSchema = schema.extend({
  name: z.string().optional(),
});

export default function ProductTable({
  includeToolbarActions = true,
  enableRowSelection = false,
  enableRowClick = true,
  enableRowActions = true,
  handleRowSelection,
  preSelectedRows,
  type = 'table',
  collectionUuid,
}: ProductTableProps) {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const search = productTableSchema.parse(Object.fromEntries(searchParams));

  const emissionsCategory = searchParams
    .get('emissionsCategory')
    ?.split('.') as AppProductsListEmissionsCategoryItem[];

  const status = searchParams.get('status')?.split('.') as StatusEnum[];

  const { data, isPreviousData, isFetchedAfterMount, isLoading, isError } =
    useGetProductsList({
      page: search.page,
      page_size: search.page_size,
      emissions_category: emissionsCategory,
      search: search.name || '',
      status: status,
      sort: [search.sort] as AppProductsListSortItem[],
      collection: collectionUuid || undefined,
    });

  if (isError) throw new Error();

  const { results: products, count } = data || {};

  const pageCount = count ? Math.ceil(count / (search.page_size || 10)) : 0;

  const filterFields: DataTableFilterField<ProductList>[] = [
    {
      label: 'Products',
      value: 'name',
      placeholder: 'Search products...',
    },
    {
      label: 'Rating',
      value: 'emissionsCategory',
      options: useGetStaticFilterOptions(EmissionsCategoryEnum),
    },
    {
      label: 'Status',
      value: 'status',
      options: useStatusFilterOptions(),
    },
  ];

  const { table } = useServerDataTable({
    data: products || [],
    columns: getColumns(),
    pageCount,
    filterFields,
    defaultPerPage: 10,
    columnVisibilityState: {
      uuid: false,
      select: enableRowSelection || false,
      actions: enableRowActions || false,
    },
    rowId: 'uuid',
    preSelectedRows: preSelectedRows,
  });
  const tableState = table.getState();

  useEffect(() => {
    if (handleRowSelection) {
      handleRowSelection(tableState.rowSelection);
    }
  }, [handleRowSelection, tableState.rowSelection]);

  const sortOptions: SortOption[] = table
    .getAllColumns()
    .filter((column) => column.getCanSort() && column.columnDef.meta?.sorting)
    .flatMap((column) => [
      {
        id: column.id,
        label: column.columnDef.meta!.sorting!.ascLabel,
      },
      {
        id: `-${column.id}`,
        label: column.columnDef.meta!.sorting!.descLabel,
      },
    ]);

  if (isLoading) return <Loader />;

  return (
    <DataTable
      table={table}
      className="bg-gray-00"
      headerConfig={{ className: 'bg-primary' }}
      showHeader={type === 'card' ? false : true}
      rowClassName={cn('', { 'bg-gray-25 border-none': type === 'card' })}
      onRowClick={(row) => {
        if (!enableRowClick) return;
        const uuid = row?.original.uuid;
        const status = row?.getValue('status');

        status === 'draft'
          ? navigate(`/products/${uuid}/edit`)
          : navigate(`/products/${uuid}`);
      }}
      customRowItem={
        type === 'card'
          ? (row) => (
              <ProductCard
                product={row.original}
                className="mb-3 cursor-pointer"
                onClick={() => {
                  if (!enableRowClick) return;
                  const uuid = row?.original.uuid;
                  const status = row?.getValue('status');

                  status === 'draft'
                    ? navigate(`/products/${uuid}/edit`)
                    : navigate(`/products/${uuid}`);
                }}
              />
            )
          : undefined
      }
      isLoading={(!isFetchedAfterMount && isPreviousData) || isLoading}
      enablePagination
    >
      <DataTableToolbar
        table={table}
        filterFields={filterFields}
        sortOptions={type === 'card' ? sortOptions : undefined}
      >
        {includeToolbarActions && <ProductsTableToolbarActions table={table} />}
      </DataTableToolbar>
    </DataTable>
  );
}
