import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from '@shared/components/ui/navigation-menu';
import { cn } from '@shared/lib/utils';
import { NavLink } from 'react-router-dom';
import { SubTab } from './page-tabs';

export interface PageSubTabs {
  tabs: SubTab[];
}

export function PageSubTabs({ tabs }: PageSubTabs) {
  return (
    <NavigationMenu className="w-full max-w-none justify-start overflow-y-auto bg-teal-100">
      <NavigationMenuList className="w-full space-x-0 bg-teal-100">
        {tabs
          .filter((tab) => !tab.hide)
          .map((tab) => (
            <NavigationMenuItem key={tab.title} className="w-full bg-teal-100">
              <NavLink
                to={tab.href}
                state={{
                  subTabsActive: tab.href,
                }}
                onClick={(e) => tab.disabled && e.preventDefault()}
                className={({ isActive }) => {
                  return cn(
                    'text-nowrap border-b-2 font-medium text-xs px-4 py-2.5 transition-all ease-in duration-150 flex flex-row items-center',
                    {
                      ' text-teal-950 border-b-2 border-b-teal-600': isActive,
                      ' hover:text-teal-900 hover:border-b-teal-300': !isActive,
                      'hover: cursor-default hover:text-gray-300 hover:border-b-none text-gray-300 ':
                        tab.disabled,
                    }
                  );
                }}
                end
              >
                <NavigationMenuLink>{tab.title}</NavigationMenuLink>
                {tab.icon && (
                  <div className="ml-1.5 flex h-[20px] items-center">
                    {tab.icon}
                  </div>
                )}
              </NavLink>
            </NavigationMenuItem>
          ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
}
