import { Icons } from '@shared/components/content/icons';
import Text from '@shared/components/content/text';
import { Card, CardContent } from '@shared/components/ui/card';
import { cn } from '@shared/lib/utils';
import { Link, useNavigate } from 'react-router-dom';

interface HubCardsProps {
  icon: React.ReactNode;
  name: string;
  totalEmissions: number | null;
  description: string;
  href: string;
  status: string;
  type: 'sites' | 'sources' | 'items';
  cardStatus?: 'disabled' | 'excluded' | 'default';
}

export default function HubCards({
  icon,
  name,
  totalEmissions,
  description,
  href,
  status,
  type,
  cardStatus = 'default',
}: HubCardsProps) {
  const navigate = useNavigate();
  return (
    <Link
      to={href}
      onClick={(e) => {
        if (cardStatus === 'disabled') {
          e.preventDefault();
          return;
        }
        navigate(href, {
          state: {
            subTabsActive: href,
          },
        });
      }}
    >
      <Card
        className={cn('shadow-md mb-2', {
          'opacity-50': cardStatus === 'disabled',
        })}
      >
        <CardContent className="flex flex-row items-center pb-2 pt-3">
          <div>{icon}</div>
          <span className="ml-2 text-[16px]">{name}</span>
          <div className="ml-auto flex items-baseline">
            <span
              className={cn('mr-1 text-xl text-teal-600', {
                'text-gray-600': !totalEmissions,
              })}
            >
              {totalEmissions ? totalEmissions : '-'}
            </span>
            <Text variant="unit">
              tCO<sub>2</sub>e
            </Text>
          </div>
        </CardContent>
        <CardContent className="flex justify-between">
          <Text variant="subtle">{description}</Text>
          <span className="flex items-center">
            {cardStatus == 'disabled' && (
              <>
                <Icons.hourglass className="mr-1" size={14} />
                <p>Coming soon</p>
              </>
            )}
            {cardStatus == 'excluded' && (
              <>
                <Icons.x className="mr-1 text-error" size={14} />
                <Text variant="subtle">Excluded from report</Text>
              </>
            )}
            {cardStatus == 'default' && (
              <>
                <Icons.check className="mr-1" size={14} />
                <Text>
                  {status} - {type}
                  {type === 'sites' ? ' completed' : ' added'}
                </Text>
              </>
            )}
          </span>
        </CardContent>
      </Card>
    </Link>
  );
}
