import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from '@shared/components/ui/navigation-menu';
import { cn } from '@shared/lib/utils';
import { useCallback, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { PageSubTabs } from './page-sub-tabs';

export interface Tab {
  title: string;
  icon?: React.ReactNode;
  href: string;
  disabled?: boolean;
  hide?: boolean;
  subTabs?: SubTab[];
}

export interface SubTab extends Tab {}

export interface PageTabs {
  tabs: Tab[];
}

export function PageTabs({ tabs }: PageTabs) {
  const location = useLocation();
  const [subTabs, setSubTabs] = useState<Tab[]>();

  const setSubTabsFromLocation = useCallback(() => {
    const currentMainTab = tabs.find((tab) => {
      const subTab = tab.subTabs?.find(
        (subTab) => subTab.href === location.pathname
      );
      return tab.href === location.pathname || subTab;
    });

    if (!currentMainTab) {
      return;
    }

    if (currentMainTab.subTabs) {
      setSubTabs(currentMainTab.subTabs);
      return;
    }

    setSubTabs(undefined);
  }, [location.pathname, tabs]);

  useEffect(() => {
    setSubTabsFromLocation();
  }, [setSubTabsFromLocation]);

  return (
    <div className="w-full">
      <NavigationMenu className="w-full max-w-none justify-start  overflow-y-auto">
        <NavigationMenuList className="space-x-0">
          {tabs
            .filter((tab) => !tab.hide)
            .map((tab) => (
              <NavigationMenuItem key={tab.title}>
                <NavLink
                  to={tab.href}
                  onClick={(e) => tab.disabled && e.preventDefault()}
                  className={({ isActive }) => {
                    return cn(
                      'border-b-2 font-medium text-sm px-4 py-2.5 transition-all ease-in duration-150 flex flex-row items-center',
                      {
                        ' text-teal-700 border-b-teal-600':
                          isActive ||
                          (location.state?.subTabsActive &&
                            tab.subTabs?.some(
                              (subTab) =>
                                subTab.href === location.state.subTabsActive
                            )),
                        ' hover:text-gray-700 hover:border-b-teal-300':
                          !isActive,
                        'hover: cursor-default hover:text-gray-300 hover:border-b-none text-gray-300 ':
                          tab.disabled,
                      }
                    );
                  }}
                  end={!tab.subTabs}
                >
                  <NavigationMenuLink>{tab.title}</NavigationMenuLink>
                  {tab.icon && (
                    <div className="ml-1.5 flex h-[20px] items-center">
                      {tab.icon}
                    </div>
                  )}
                </NavLink>
              </NavigationMenuItem>
            ))}
        </NavigationMenuList>
      </NavigationMenu>
      {subTabs && <PageSubTabs tabs={subTabs} />}
    </div>
  );
}
