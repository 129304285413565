import useAuth from '@app/auth/use-auth';
import Heading from '@shared/components/content/heading';
import Text from '@shared/components/content/text';
import { Badge } from '@shared/components/ui/badge';
import { cn } from '@shared/lib/utils';
import { ReactElement } from 'react';
import { MobileSidebar } from '../../nav/sidebar';
import {
  dishUserSidebarItems,
  productUserSidebarItems,
} from '../../nav/sidebar-items';
import PageBreadcrumb, { Breadcrumb } from './page-breadcrumb';
import { PageHeaderToolbarProps } from './page-header-toolbar';

interface PageHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string | ReactElement;
  nameTag?: string;
  description?: string;
  nameActions?: ReactElement;
  toolbar?: ReactElement<PageHeaderToolbarProps>;
  tabs?: ReactElement;
  includeBreadcrumb?: boolean;
  breadcrumbs?: Breadcrumb[];
}

export function PageHeader({
  name,
  nameTag,
  description,
  nameActions,
  className,
  toolbar,
  tabs,
  includeBreadcrumb = true,
  breadcrumbs,
}: PageHeaderProps) {
  const { user } = useAuth();

  const items =
    user?.productCategory === 'product'
      ? productUserSidebarItems
      : dishUserSidebarItems;

  return (
    <div className={cn('mb-6 pt-1', className)}>
      <div className="flex min-h-5 items-center">
        <div className="mr-2 lg:hidden">
          <MobileSidebar items={items} />
        </div>

        {includeBreadcrumb && <PageBreadcrumb breadcrumbs={breadcrumbs} />}
      </div>
      <div className="flex items-center">
        {typeof name === 'string' ? (
          <Heading variant="subtitle" className="font-semibold">
            {name}
          </Heading>
        ) : (
          name
        )}
        {nameTag && <Badge className="ml-3">{nameTag}</Badge>}
        <div className="ml-auto">{toolbar}</div>
      </div>
      {description && (
        <Text variant="subtle" className="mt-2 max-w-xl">
          {description}
        </Text>
      )}
      {nameActions && <div className="mt-4">{nameActions}</div>}
      <div className="mt-2 w-full">{tabs}</div>
    </div>
  );
}
