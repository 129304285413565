import { Tab } from '@shared/components/page-tabs';

export const tabs: Tab[] = [
  {
    title: 'Summary',
    href: `/company-reports/uuid`,
  },
  {
    title: 'Scope 1',
    href: `/company-reports/uuid/stationary-combustion`,
    subTabs: [
      {
        title: 'Stationary combustion',
        href: `/company-reports/uuid/stationary-combustion`,
      },
      {
        title: 'Mobile combustion',
        href: `/company-reports/uuid/mobile-combustion`,
      },
      {
        title: 'Other gas emissions',
        href: `/company-reports/uuid/other-gas`,
      },
    ],
  },
  {
    title: 'Scope 2',
    href: `/company-reports/uuid/electricity`,
    subTabs: [
      {
        title: 'Electricity',
        href: `/company-reports/uuid/electricity`,
      },
      {
        title: 'Other purchased energy',
        href: `/company-reports/uuid/other-energy`,
      },
    ],
  },
  {
    title: 'Scope 3',
    href: `/company-reports/uuid/food-and-drink`,
    subTabs: [
      {
        title: 'Food and Drink',
        href: `/company-reports/uuid/food-and-drink`,
      },
      {
        title: 'Packaging',
        href: `/company-reports/uuid/packaging`,
        disabled: true,
      },
      {
        title: 'Waste',
        href: `/company-reports/uuid/waste`,
        disabled: true,
      },
    ],
  },
];
