import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@shared/components/ui/breadcrumb';
import { capitalizeFirstLetter, cn, removeDash } from '@shared/lib/utils';
import { ClassValue } from 'clsx';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export type Breadcrumb = {
  label: string;
  url: string;
};

interface PageBreadcrumbProps {
  className?: ClassValue;
  breadcrumbs?: Breadcrumb[];
}

export default function PageBreadcrumb({
  className,
  breadcrumbs,
}: PageBreadcrumbProps) {
  const location = useLocation();

  const crumbFromUrl = location.pathname
    .split('/')
    .filter((crumb) => crumb)
    .map((crumb) => {
      return {
        label: removeDash(capitalizeFirstLetter(crumb)),
        url: `/${crumb}`,
      } as Breadcrumb;
    });

  const crumbs = breadcrumbs || crumbFromUrl;

  return (
    <Breadcrumb className={cn('text-xs', className)}>
      {crumbs.length > 1 ? (
        <BreadcrumbList className="text-xs">
          {crumbs.map((crumb, index) => {
            const last = index === crumbs.length - 1;
            const to = crumb.url;

            return (
              <React.Fragment key={index}>
                <BreadcrumbItem className="max-w-60">
                  {last ? (
                    <BreadcrumbPage className="truncate">
                      {crumb.label}
                    </BreadcrumbPage>
                  ) : (
                    <BreadcrumbLink asChild className="truncate">
                      <Link to={to}>{crumb.label}</Link>
                    </BreadcrumbLink>
                  )}
                </BreadcrumbItem>
                {index < crumbs.length - 1 && <BreadcrumbSeparator />}
              </React.Fragment>
            );
          })}
        </BreadcrumbList>
      ) : null}
    </Breadcrumb>
  );
}
