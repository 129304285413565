import Page from '@app/components/layout/page/page';

export default function MobileCombustionPage() {
  return (
    <Page
      title="Scope 1 - Mobile Combustion"
      name="Scope 1 - Mobile Combustion"
    >
      Mobile combustion
    </Page>
  );
}
