import { PageHeader } from '@app/components/layout/page/components/page-header';
import Page from '@app/components/layout/page/page';
import { PageTabs } from '@shared/components/page-tabs';
import { Button } from '@shared/components/ui/button';
import { Outlet } from 'react-router-dom';
import { tabs } from './tabs';

export default function CompanyReportLayout() {
  const breadcrumbs = [
    {
      label: 'Company Reports',
      url: '/company-reports',
    },
    {
      label: 'Company Carbon Report June 2024 - May 2024',
      url: '/company-reports/uuid',
    },
  ];

  return (
    <Page name="Company Carbon Footprint Hub" page="layout">
      <PageHeader
        name="Company Carbon Report"
        description="June 2024 - May 2024"
        tabs={<PageTabs tabs={tabs} />}
        toolbar={<Button>Complete</Button>}
        breadcrumbs={breadcrumbs}
      />
      <Outlet />
    </Page>
  );
}
